#root{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.App{
  width: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: center;
}

header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  height:58px;
  max-width: 500px;
  width: 100%;
  background: linear-gradient(90deg, #272727 2.27%, #1F1526 23.14%, #291626 62.33%);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.12);


}
.vl{
  height: 40px;
  width: 2px;
  background: #D8285F;

}
.button{
  width:160px;
  height: 36px;
  outline: none;
  border: none;
  font-weight: 500;
  margin-right: 24px;
  color: white;
  background: linear-gradient(180deg, #FF85AD 0%, #E14E87 100%);
  border-radius: 30px;
  font-family: 'Rubik';
}
.button3{
  background: linear-gradient(180deg, #DB5186 0%, #C6236A 100%);
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 30px;
  font-family: 'Rubik';
  font-weight: 500;
  outline: none;
  border: none;
  color: white;
  height: 48px;
  width: 90%;
  font-size: 16px;
  line-height: 130%;
  font-weight: 500;
}
.button6{
  background: linear-gradient(180deg, #DB5186 0%, #C6236A 100%);
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 30px;
}
.button2{
  font-size: 18px;
  line-height: 22px;
  color: #0E0F10;
}
.multicarousel{
  width: 95%;
  max-width: 400px;
}
.slider__img{
  width: 249px;
}

.arrow{
  height: 10px;
  width: 10px;
  border: 10px solid red;
  background: green;
}
.text{
  margin-left: 15px;
  margin-top: 43px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.04em;
  color: white;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
}
.react-multi-carousel-dot button{
  width: 13px!important;
  height: 2px!important;
  border-radius: 0px!important;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 2px!important;
  border: none!important;
}
.react-multi-carousel-dot--active button{
  background: white!important;
}
.react-multi-carousel-dot-list{
  bottom: -5px!important;
}
.logoText{
  margin-left: 8px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 110%;
  color: #2E2E2E;
}
.qwe{
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: #FFFFFF;
}
.button4{
  background: #68A3F0;
  border-radius: 25px;
  width: 194px;
  height: 56px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  position: absolute;
  right: 30px;
  top:33px;
  border: none;
  outline: none;
}
.button5{
  background: #68A3F0;
  border-radius: 25px;
  width: 90%;
  height: 56px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  border: none;
  outline: none;
  margin-top: 160%;
}
.text11{
  font-family: Nunito;
  margin-top: 70px;
  font-style: normal;
  font-weight: bold;
  width: 100%;
  text-align: left;
  font-size: 26px;
  line-height: 108%;
  width: 90%;
  margin-bottom: 0px;
  /* or 28px */

  text-align: center;

  color: #FFFFFF;
}
.text12{
  font-family: Nunito;
  font-style: normal;
  margin:0;
  font-weight: normal;
  font-size: 15px;
  line-height: 130%;
  width: 90%;
  text-align: center;
  color: #EDEDED;
}
.text13{
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 130%;
  /* identical to box height, or 22px */

  text-align: center;

  color: #F3EEEE;
}
.bggg{
  background-image: url('./components/multilanding.png');
  background-position: center;
  background-size: cover;
}